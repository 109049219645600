import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { LocalStoreService } from '../../services/localstore.service';
import { KioskauthService } from '../../services/kioskauth.service';
import { ApiService } from '../../services/api.service';
import { UtilityService } from '../../services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateConfigService } from '../../services/translate-config.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TranslateService } from '@ngx-translate/core';
import {  setIntervalTimeOut, telemetryTimeout, DOMAIN_URL, KIOSK_AUTO_LOGIN, apitimeout, card_page_redirection_time, enable_test_buttons, terminalCheck, LOCAL_MAYA_PAYMENT_API_URL } from '../../common/api';
import { timeout } from 'rxjs/operators';
import { KioskapiService } from '../../services/kioskapi.service';
import { CancelPaymentPage } from '../cancel-payment/cancel-payment.page';
import * as sha1 from 'js-sha1';
import { environment } from 'src/environments/environment';
import { RetryPaymentPage } from '../../retry-payment/retry-payment.page';
import * as moment from 'moment';
import { PaymentSuccessModalPage } from '../../payment-success-modal/payment-success-modal.page';

@Component({
  selector: 'app-maya-payment-processing',
  templateUrl: './maya-payment-processing.component.html',
  styleUrls: ['./maya-payment-processing.component.scss'],
})
export class MayaPaymentProcessingComponent implements OnInit {
  counter;
  cartItems: any = [];
  totalAmount: number;
  currency = "¥";
  showDecimal = true;
  interval: any;
  proc_card_idi;
  pay_sub: any;
  createorderRes;
  wait_for_payment;
  temp_balance: any;
  temp_total: any;
  _suica_alert: any;
  suica_alert_flag = 0;
  ServiceID: any;
  modal_time_count = 0;
  modal_timer;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  page_redirection_time_left = card_page_redirection_time;
  time_left_redirection_counter: any;
  attempted: any;
  deducted: any;
  balance: any;
  card_idi: any;
  ic_handling_number: any;
  order_UUID;
  isPaymentFailed;
  app_settings;
  message: any;
  test_pay_sub;
  test_button_status = enable_test_buttons;
  terminalid;
  loading;
  telemetry_poller;
  terminal_modal;
  translatedText;
  mayaPaymentProcessInterval;
  mayaPaymentStatusInterval;
  mayaPaymentCheckStatusInterval;
  mayaPaymentProcessing = false;
  timer_for_ui;

  constructor(
    private store: LocalStoreService,
    private router: Router,
    private kioskauthservice: KioskauthService,
    public alertController: AlertController,
    private api: ApiService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    public modalController: ModalController,
    private kiosk_apiService : KioskapiService,
    private loadingCtrl:LoadingController
  ) {
    this.translateConfigService.getDefaultLanguage();

    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  }

  ngOnInit() {
    this.counter = 0;
    // this.Page_timer();
  }

  

  ionViewWillEnter() {
    this.mayaPaymentProcessing = false;
    console.log("ionviewwill entwe called")
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    this.isRestaurantUnderMaintainance();
    this.payment_printing_setting();
    this.getTranslatedText();
    this.terminalid = this.store.get('terminalid');
    if (!this.store.get('sessionId')) {
      this.create_sessionId();
    }
  }
  getTranslatedText() {
    this.translatedText = this.store.get('translatedText');
  }
  create_sessionId() {
    const sessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');;
    this.store.set('sessionId', sessionId);
  }



  ionViewDidEnter() {
    this.counter = 0;
  }

  isRestaurantUnderMaintainance() {
    let param = {
      master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
    this.kioskauthservice.apiReq('post', 'masterrestaurant/getbyuuid', param)
      .subscribe((res) => {
        if (res.statusCode == 200) {
          if (res.aaData['master_restaurant_status'] == 'M_ON') {
            this.router.navigate([`home`]);
          } else {
            this.loadAllData();
           
          }
        } else {
          this.loadAllData();
        }
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.error()
          }
        }
      }, err => {
        this.error()
      });
  }

  
  Page_timer() {
   
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = card_page_redirection_time;
    this.timer_for_ui = 60;
    if (this.page_redirection_time_left = card_page_redirection_time) {

      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        this.timer_for_ui -= 1;
        if (this.page_redirection_time_left == 10) {
          console.log('here abort payment will be called');
          // this.abort_order();
        }
        if (this.page_redirection_time_left == 0) {
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.telemetry_poller)
          clearInterval(this.time_left_redirection_counter);
          clearInterval(this.mayaPaymentProcessInterval);
          // this.store.setUserCart([]);
          this.time_out();
          // this.store.remove("formFields");
          // this.store.remove("cart");
          return false;
        }
      }, setIntervalTimeOut);
    }
  }


  loadAllData() {
    const orderId = this.store.get('orderId')
    const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    if (items.length == 0 && !orderId) {
      this.router.navigate([`home`]);
      return false;
    }
    else{
      this.create_kiosk_order();
    }
  }
  createOrderResponse;

  create_kiosk_order() {

    const paymentPayload  = localStorage.getItem('paymentrequest-payload');
    let res = JSON.parse(paymentPayload);
    this.mayaPosPaymentStart(res);
  }
  
  
    payment_response_process(payment_info) {
      if (payment_info.statusCode == 200) {
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.kiosk_apiService.logEvents("PAYMENT", payment_info, "SUCCESS");
        this.kiosk_apiService.audit_log("PAYMENT", payment_info, "SUCCESS");
        this.updateOrder(this.createorderRes, payment_info, "QUEUED");
      }
      else {
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.kiosk_apiService.logEvents("PAYMENT", payment_info, "PAYMENT_ERROR");
        this.kiosk_apiService.audit_log("PAYMENT", payment_info, "PAYMENT_ERROR");
        this.payment_response_modal(payment_info.message)
      }
    }
  
    updateOrder(orderData, paymentResponse, isPaymentFailed) {
      this.deducted = this.totalAmount;
      // let terminalid = this.store.get('terminalid')
      this.order_UUID = orderData.aaData.order_info_uuid
      this.isPaymentFailed = isPaymentFailed;
      this.balance = paymentResponse.data ? paymentResponse.data.amt : "";
      let mid = paymentResponse.data ? paymentResponse.data.aid : "";
      let data = {
        order_info_uuid: this.order_UUID,
        restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        payment_response: paymentResponse,
        payment_status: isPaymentFailed ? isPaymentFailed : '',
        suica_payment_details: { "deducted": this.deducted, "balance": this.balance, "paymentid": mid, "RESPONSE": paymentResponse }
      }
  
      this.kiosk_apiService.logEvents("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");
      this.kiosk_apiService.audit_log("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");
  
      this.kiosk_apiService.updateOrder(data).subscribe(res => {
        this.kiosk_apiService.logEvents("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
        this.kiosk_apiService.audit_log("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
        this.store.remove("orderId");
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        if (this.isPaymentFailed == 'QUEUED' && this.app_settings?.enable_kiosk_print) {
          const redirectUrl = "/settlement-complete?id=" + this.order_UUID + "&c=" + this.proc_card_idi + "&b=";
          // this.paymentSuccessModal(redirectUrl);
  
          this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID + "&c=" + this.proc_card_idi + "&b=");
        }
        else if (!this.app_settings?.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = this.store.get('autoLoginUrl')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
        }
        return false;
      }, err => {
        this.kiosk_apiService.logEvents("PAYMENT", err, "UPDATE_ERR");
        this.kiosk_apiService.audit_log("PAYMENT", err, "UPDATE_ERR");
        const payment_cancel_timeout = setTimeout(() => {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = this.store.get('autoLoginUrl')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
        }, 100)
      }
      );
  
    }
  
  
    cancelOrder() {
      clearInterval(this.time_left_redirection_counter);
      this.mid_cancel = true;
      let cancel_payment = {
        serviceId: this.ServiceID,
        terminalid: this.terminalid
      }
      this.kiosk_apiService.logEvents("PAYMENT", cancel_payment, "CANCEL_PAYMENT_PAYLOAD");
      this.kiosk_apiService.audit_log("PAYMENT", cancel_payment, "CANCEL_PAYMENT_PAYLOAD");
      this.api.wait_for_abort_payment(this.ServiceID, this.terminalid)
        .subscribe(res => {
          this.kiosk_apiService.logEvents("PAYMENT", res, "ABORT");
          this.kiosk_apiService.audit_log("PAYMENT", res, "ABORT");
        });
     
      this.store.remove("orderId");
      this.store.remove('sessionId');
      this.ServiceID = this.generate_rand_num();
      this.router.navigate([`cart-list`]);
    }
  
  
    abort_order() {
      // if (this.pay_sub)
      // this.pay_sub.unsubscribe()
      let terminalid = this.store.get('terminalid')
      let data = {
        serviceId: this.ServiceID,
        terminalid: terminalid
      }
      this.kiosk_apiService.logEvents("PAYMENT", data, "ABORT_ORDER_PAYLOAD");
      this.kiosk_apiService.audit_log("PAYMENT", data, "ABORT_ORDER_PAYLOAD");
  
      this.api.wait_for_abort_payment(this.ServiceID, terminalid)
        .subscribe(res => {
          this.kiosk_apiService.logEvents("PAYMENT", res, "ABORT");
          this.kiosk_apiService.audit_log("PAYMENT", res, "ABORT");
        });
  
    }

    generate_rand_num() {
      var rand: any;
      rand = Math.random();
      rand = rand * 10000000000;
      rand = Math.floor(rand);
      rand = '' + rand;
      rand = rand.substr(0, 8);
      return rand;
    }
  
    payment_printing_setting() {
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
      let data = {
        "master_restaurant_uuid": master_restaurant_uuid
      }
      this.kiosk_apiService.payment_printing_setting(data)
        .subscribe(
          (res) => {
            this.app_settings = res.aaData.module_permissions
          },
          error => {
          }
        );
    }
  
    ionViewDidLeave() {
      // this.store.setUserCart([]);
      // this.store.remove("formFields");
      // this.store.remove("cart");
      clearInterval(this.terminal_modal)
      clearInterval(this.telemetry_poller);
      clearInterval(this.time_left_redirection_counter);
      clearInterval(this.mayaPaymentProcessInterval);
      clearInterval(this.mayaPaymentStatusInterval);
      this.store.remove("orderId");
      if (this.pay_sub)
      this.pay_sub.unsubscribe();
    }


  //Abort payment
  async cancel_payment() {
    clearInterval(this.time_left_redirection_counter);
    const alert = await this.modalController.create({
      component: CancelPaymentPage,
      cssClass: 'cancel-payment'
    }
    );
    return await alert.present();
  }
 
  async processing(){
   
    this.loading = await this.loadingCtrl.create({
      message: '<div  class="conatiner"><ion-img class="ella-logo" src="../../assets/image/Ella_loading.gif" alt="loading..."></ion-img><p class ="text">Processing Your Payment</p><ion-img class="ella-loader" src="../../assets/image/loading.gif" alt="_loading..."></ion-img></div>',
      cssClass: 'loader-class',
      spinner: null
    });
    await this.loading.present();
    
      
    }


    payment_response_modal(message) {
      const repaymentCount = this.store.get('retryPaymentCount');
      if (repaymentCount >= 3) {
        this.autoLogin();
      } else {
        this.payment_response_present_modal(message);
      }
    }

    async payment_response_present_modal(message) {
      clearInterval(this.time_left_redirection_counter);
      this.message = message;
      this.mayaPaymentProcessing = false;
      const modal = await this.modalController.create({
        component: RetryPaymentPage,
        cssClass: 'payment-error-modal',
        backdropDismiss: false,
        componentProps: {
          'responseMessage': this.message,
        }
      });
      modal.onDidDismiss().then((data) => {
        console.log("payment-error-modal", data);
        if (data.data.dismiss_type == 'retry' && data.data.resetPaymentCount <= 3) {
          this.ServiceID = this.generate_rand_num();
          this.retryOrderPayment();
        } 
        else if (data.data.dismiss_type == 'cancelOrder') {
          this.cencelOrder();
        } else {
          this.autoLogin();
        }
      });
      return await modal.present();
    }

    time_out() {
      this.payment_response_modal(this.translatedText.timeout)
    }
    error(message?) {
      this.payment_response_modal(this.translatedText.redirect_back_5_seconds)
    }
    technical_error(message?) {
      const passMessage = message ? message : this.translatedText.technical_error;
      this.payment_response_modal(passMessage)
    }

    retryOrderPayment() {
      this.terminal_modal = this.store.get('terminal_modal')
      setInterval( () =>{
        this.terminal_modal = this.store.get('terminal_modal')
      },500)
      this.kiosk_apiService.telemetry();
      this.telemetry_poller = setInterval(
        ()=>{
        this.kiosk_apiService.telemetry()
        }
      , terminalCheck);
      // this.Page_timer();
      // this.createPayment(this.createOrderResponse);
      this.mayaPosPaymentStart(this.createOrderResponse)
    }
    cencelOrder() {
      this.store.remove("orderId");
      this.store.remove('sessionId');
      this.ServiceID = this.generate_rand_num();
      this.router.navigate([`cart-list`]);
    }
    autoLogin() {
      clearInterval(this.time_left_redirection_counter);
      let Autologin = this.store.get('autoLoginUrl')
      this.store.hardlogout()
      location.href = DOMAIN_URL + Autologin;
    }
    
    async paymentSuccessModal(redirectUrl) {
      clearInterval(this.time_left_redirection_counter);
      const modal = await this.modalController.create({
        component: PaymentSuccessModalPage,
        cssClass: 'payment-sucess-modal',
        backdropDismiss: false,
      });
      modal.onDidDismiss().then((data) => {
        console.log("payment-success-modal", 'redirect to next page');
        clearInterval(this.terminal_modal)
        clearInterval(this.telemetry_poller);
        this.router.navigateByUrl(redirectUrl)
      });
      return await modal.present();
    }

    mayaPosPaymentStart(createOrderRes) {
      // this.Page_timer();
      this.mayaPaymentProcessInterval = setInterval(() => {
        this.mayaPosPaymentStartInterval(createOrderRes)
      }, 1000)
    }
    mayaPosPaymentStartInterval(createOrderRes) {
      this.api.mayaPaymentProcessQR(createOrderRes.price, createOrderRes.merchantRefNo, createOrderRes.orderNo).subscribe(
        (response) => {
          if (response?.status == 'ack') {
            this.Page_timer();
            clearInterval(this.mayaPaymentProcessInterval);
            this.checkForpaymentStatus()
          }
      })
      // clearInterval(this.mayaPaymentProcessInterval);
      // this.checkForpaymentStatus()
    }
    checkForpaymentStatus() {
      this.mayaPaymentStatusInterval = setInterval(() => {
        this.checkForpaymentStatusInterval()
      }, 1000)
    }
    checkForpaymentStatusInterval() {
      console.log('Step 2')
      this.api.mayaPaymentCheckStatus().subscribe(
        (response) => {
          this.Page_timer();
          this.mayaPaymentProcessing = true;
          this.checkForpaymentCheckStatus();
      })
    }
    checkForpaymentCheckStatus() {
      this.mayaPaymentCheckStatusInterval = setInterval(() => {
        this.checkForpaymentCheckStatusInterval()
      }, 1000)
    }
    checkForpaymentCheckStatusInterval() {
      console.log('Step 3')
      this.api.mayaPaymentCheckStatus().subscribe(
        (response) => {
          localStorage.removeItem('paymentrequest-payload');
          if (response?.data?.status == 'A') {
            clearInterval(this.mayaPaymentStatusInterval);
            this.updateOrder(this.createOrderResponse, response, 'QUEUED');
          } else if (response?.data?.status == 'R' || response?.data?.status == 'C') {
            this.kiosk_apiService.logEvents("PAYMENT", response, "PAYMENT_FAILED");
            this.kiosk_apiService.audit_log("PAYMENT", response, "PAYMENT_FAILED");
            clearInterval(this.mayaPaymentCheckStatusInterval);
            this.payment_response_modal(response?.data?.responseText)
          }
      })
    }
  
  }